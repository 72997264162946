.bot-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    background-color: purple;
    color : white;
    margin-right: 10px;
}