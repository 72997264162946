@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed');


.loading-icon {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.9);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.loading-icon .loading-icon-gif {
    width: 100px;
    height: 100px;
}


.MainContainer{
    display: flex;
    flex-direction: row;
    font-family: 'Roboto Condensed', sans-serif;
}

.leaflet-container {
    min-width: 60vw;
    min-height: 80vh;
    height: 89.5vh;
}


.Menu-Appart{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 40vw;
    height: 89.5vh;
    margin-bottom: 0;

}

.Menu-Appart-Toolbars{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    padding-bottom: 1rem;
    background-color: aliceblue;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
}

.Menu-Appart-Toolbars-first{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    margin-top: 1rem;
}

.Menu-Appart-Toolbars-first .Search-Appart {
    width: 23vw;
    border: none;
    padding: 0.5rem;
    font-size: 1rem;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}

.Menu-Appart-Toolbars-first .Search-Appart:focus {
    outline: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.76);
}

.Menu-Appart-Toolbars-first .Search-Appart::placeholder{
    font-size: 1rem;
    color: black;
    padding: 0.1rem;
}

.Menu-Appart-Toolbars select {
    width: 7vw;
    border: none;
    padding: 0.5rem;
    font-size: 1rem;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;

}

.Menu-Appart-Toolbars select:focus {
    outline: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.76);
}

.Menu-Appart-Toolbars select::placeholder{
    font-size: 1rem;
    color: black;
    padding: 0.1rem;
}

.Menu-Appart-Toolbars button {
    width: 15%;
    border: none;
    padding: 0.5rem;
    font-size: 1rem;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    background-color: white;
    transition: box-shadow 0.3s ease;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.Menu-Appart-Toolbars button:hover {
    outline: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.76);
}


.Menu-Appart-Toolbars-second {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;

}


.Menu-Appart-Toolbars-second select{
    width: 8.26vw;
}

.Menu-Appart-List-Container {
    max-height: 90vh;
    overflow-y: auto;

}

.Menu-Appart-List {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
    padding: 1rem;
    background-color: white;

}
.Menu-Appart-List-Item {
    display: flex;
    flex-direction: column;
    width: 40%;
    height: auto;
    gap: 1rem;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    transition: box-shadow 0.3s ease;
}

.Menu-Appart-List-Item:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.76);
}

.Menu-Appart-List-Item-Content {
    padding: 1rem;
    padding-top: 0;
}

.Menu-Appart-List-Item-Img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px; 
    overflow: hidden;
    position: relative;
}

.Menu-Appart-List-Item-Img img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    object-position: center;
}



.Menu-Appart-List-Item video {
    object-fit: cover;
    object-position: center;
    min-width: 5%;
    min-height: 5%;
    max-height: 30vh;
    max-width: 17vw;
}


/* Page Information appartement */

.Menu-Information-Appart {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color:#FFFFFF;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
}

.Menu-Information-Appart-Button-back {

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-color: #F1F3F4;
    padding: 0.5rem;
}

.Menu-Information-Appart-Button-back button {
    display: flex;
    align-items: center;
    border: none;
    padding: 0.5rem;
    font-size: 1rem;
    border-radius: 4px;
    background-color: none;
    transition: box-shadow 0.3s ease;

}

.Menu-Information-Appart-Button-back button:hover {
    outline: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.76);
    cursor: pointer;
}

.Menu-Information-Appart-Button-back .Arrow-Back-Btn  {
    font-size: 35px;
    position: absolute;
    line-height: 43px;
    height: 43px;
}

.Menu-Information-Appart-Button-back .Text-Back-Btn{
    font-size: 17px;
    font-weight: bold;
    display: inline;
    vertical-align: baseline;
    margin-left: 0;
    height: 19px;
    padding-left: 25px;
}

.Menu-Information-Appart-Content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
    max-height: 80vh;
    overflow-y: auto;

}

.Menu-Information-Appart-Title {
    margin-bottom: 1rem;
}


.Title-type {
    font-size: 1rem;
    color: #009DB0;

}
.Menu-Information-Appart-Title-Info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Menu-Information-Appart-Title h2 {
    font-size: 1.5rem;
    font-weight: bold;
}

.Menu-Information-Appart-Title h4 {
    font-size: 1rem;
}

.Menu-Information-Appart-Title p {
    font-size: 2.5rem;
    color: #009DB0;
    font-weight: bold;
}

.Menu-Information-Appart-Title-Info .Contact-Info {
    font-size: 1.1rem;
    text-align: center;
    font-weight: bold;
    color: red;
}

.Carousel-Container {
    display: flex;
    justify-content: center; 
    align-items: center;
    margin-bottom: 1rem;
}

.carousel {
    align-items: center;
    position: relative;
    background-color: #333;
    width: 80%;
    height: 40vh;
    
}
  
.carousel img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.carousel video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}


.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    color: #333;
    font-size: 1.5rem;
    padding: 10px;
    cursor: pointer;
}

.arrow.left {
    z-index: 1000;
    left: 0;
}

.arrow.right {
    right: 0;
}

.full-screen-button {
    position: absolute;
    top: 8%;
    left: 90%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    color: #333;
    font-size: 1.5rem;
    padding: 10px;
    cursor: pointer;
    content: "\f0b2";
}

.full-screen-button:hover{
   color: rgb(167, 163, 163);
}

.Count-Images{
    position: absolute;
    top: 90%;
    left: 90%;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;


}

.Count-Images h5{
    color:white;
    font-size: 1.5rem;
    margin-left: 0.1rem;
}


.Count-Images img {
    width: 50%;
    height: 50%;
}
  

.carousel.full-screen {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999; 
}

.Full-Screen-Info {
    position: absolute;
    display: flex;
    align-items: center;
    top: 0%;
    left: 0%;
    width: 20vw;
    height: 7vh;
    background-color: #495960;
    color:white;
}

.Full-Screen-Info h3 {
    font-size: 1.5rem;
    text-align: center;
    margin-left: 0.5rem;
}
.carousel.full-screen img {

    width: 75%;
    height: 75%;
    object-fit: none;
    object-position: none;

}

.full-screen .full-screen-button {
    position: absolute;
    top: 4%;
    left: 96%;
    width: 3vw;
    height: 5vh;
    color: white;
    background-color: #009DB0;
    border: none;
    padding: 0.5rem;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    font-size: large;
}

.full-screen .full-screen-button :hover {
    background-color: #007d8c;
}

.Menu-Information-Description {
    display: flex;
    flex-direction: column;

}

.Menu-Information-Description h2{
    margin-bottom: 1rem;
}

.Menu-Information-Description p {
    width: 80%;
    margin: auto;
}

.Menu-Information-Description ul {
    width: 80%;
    margin: auto;
}


@media screen and (max-width: 1128px) {

    .MainContainer{
        display: flex;
        flex-direction: column;
    }

    .leaflet-container {
        min-width: 100vw;
        min-height: 20vh;
        height: 50.5vh;
    }

    .Menu-Appart {
        width: 100vw;
        height: 50.5vh;
    }

    .Menu-Appart-List-Item {
        width: 30%;
    }


    .Menu-Appart-Toolbars-first .Search-Appart {
        width: 65vw;
    }

    .Menu-Appart-Toolbars-second select {
        width: 20vw;
    }

    .Menu-Appart-Toolbars button {
        width: 20%;
    }
}


@media screen and (max-width: 768px) {


    .Menu-Appart-List-Item {
        width: 40%;
    }

}

@media screen and (max-width: 480px) {

    .Menu-Appart-List{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
        padding: 1rem;
        background-color: white;
    }

    .Menu-Appart-List-Item {
        width: 60%;
    }


    .Menu-Appart-Toolbars-first .Search-Appart {
        width: 65vw;
    }

    .Menu-Appart-Toolbars-second select {
        width: 35vw;
    }

    .Menu-Appart-Toolbars button {
        width: 20%;
    }

}