.Main-Content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.login {
    background-color: #f2f2f2;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.login-title {
    text-align: center;
    margin-bottom: 20px;
}

.form-login {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 10px;
}

.form-login.form-group label {
    font-weight: bold;
}

.form-login.form-group input[type="text"],
.form-login.form-group input[type="password"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.btn-login {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.btn-login:hover {
    background-color: #0056b3;
}