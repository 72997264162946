.text-title {
    font-size: 2rem;
    color: #000;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 125px;
}

.text {
    font-size: 1.2rem;
    color: #000;
    text-align: left;
    margin-bottom: 20px;
    margin-left: 125px;
    word-wrap: break-word;
    width: 50%; 
}

.video-container-title {
    font-size: 2rem;
    color: #000;
    text-align: left;
    margin-bottom: 20px;
    margin-left: 125px;
}

.video-container {
    display: flex;
    flex-direction: column;
    gap: 35px;
    padding: 20px;
}

.video-row {
    display: flex;
    justify-content: center;
}

.video-text {
    display: flex;
    flex-direction: column;
    width: 35%;
}

.video-text h3 {
    text-align: left;
    margin-bottom: 10px;
    margin-left: 20px;
    width: 65%; 
}

.video-text p {
    font-size: 1.05rem;
    color: #000;
    text-align: left;
    margin-bottom: 20px;
    margin-left: 20px;
    width: 65%; 
}

.video-container iframe {
    width: 85%;
    height: 350px;
    padding: 20px;
    
}

@media screen and (max-width: 768px) {


    .text-title {
        margin-left: 15px;
    }

    .text {
        margin-left: 15px;
        width: 90%;
    }


    .video-container-title {
        margin-left: 15px;
    }

    .video-text {
        width: 100%;
    }

    .video-text h3 {
        width: 100%;
    }

    .video-text p {
        width: 100%;
    }

    .video-container iframe {
        width: 100%;
        height: 250px;
    }

}

@media screen and (max-width: 480px) {

    .text-title {
        font-size: 1.5rem;
    }

    .text {
        font-size: 1rem;
    }

    .video-container-title {
        font-size: 1.5rem;
    }

    .video-text h3 {
        font-size: 1.2rem;
    }

    .video-text p {
        font-size: 0.8rem;
    }

    .video-container iframe {
        height: 200px;
    }

}