.options-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
}


.options-container .option-button {
    max-width: 100%;
    width: 60%;
    background-color: #1591da;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 5px;
    cursor: pointer;
    transition: background-color 0.5s;
    font-size: 0.75rem;
    font-weight: bold;
}

.options-container .option-button:hover {
  background-color: #5fc3f5;
}

.options-container-title {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 10px;
    background-color: purple;
    color: white;
    border-radius: 5px;
}

.options-container-title p {
    padding: 10px;
}