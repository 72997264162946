.Main-Content-dasboard {
    background-color: #f2f2f2;
    height: 100vh;
}

.admin-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.admin-header h1 {
    font-size: 24px;
    color: #333;
    margin-left: 10px; 
}

.btn-logout {
    margin-right: 10px; 
    padding: 8px 16px;
    background-color: #f44336;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.admin-dashboard {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-left: 20px;
}

.btn-admin {
    padding: 12px 24px;
    margin-bottom: 10px;
    margin-left: 20px;
    background-color: #2196f3;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.admin-dashboard-Content {
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-left: 20px;
}


.admin-dashboard-addAppartement {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    margin-bottom: 20px;
}

.form-addAppartement {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    max-width: 800px;
    width: 100%;
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.form-addAppartement .form-group label {
    margin-bottom: 5px;
}

.form-addAppartement input,
.form-addAppartement select,
.form-addAppartement textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
}

.form-group textarea {
    resize: vertical;
}

.btn-addAppartement {
    grid-column: span 4;
    padding: 10px 20px;
    background-color: #2196f3;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}


.admin-dashboard-updateAppartement {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    margin-bottom: 20px;
}

.form-updateAppartement {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    max-width: 800px;
    width: 100%;
    margin-top: 50px;
}

.form-updateAppartement .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.form-updateAppartement .form-group label {
    margin-bottom: 5px;
}

.form-updateAppartement .form-group input,
.form-updateAppartement .form-group select,
.form-updateAppartement .form-group textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
}

.form-updateAppartement .form-group textarea {
    resize: vertical;
}

.btn-updateAppartement {
    grid-column: span 4;
    padding: 10px 40px;
    background-color: #2196f3;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.admin-dashboard-deleteAppartement {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    margin-bottom: 20px;
}

.form-group-deleteAppartement {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.form-group-deleteAppartement label {
    margin-bottom: 5px;
}

.admin-dashboard-deleteAppartement .btn-deleteAppartement {
    padding: 10px 20px;
    background-color: #f44336;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.admin-dashboard-deleteAppartement .btn-deleteAppartement:hover {
    background-color: #ff0000;
}

