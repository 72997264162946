.Home {
    background-color: #f1f1f1;
}

.slideshow-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #ffffff;
}

.mySlides {
    display: none;
    width: 100%;
    height: 100%;
    transition: opacity 1s;
}

.mySlides img {
    width: 100%;
    object-fit: contain;
}

.dots-container {
    position: absolute;
    top: 95%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.dot {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin: 0 2px;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.6s ease;
}

.dot.active {
    background-color: black;
}


.Information-content {
    display: flex;
    justify-content: space-between;
    height: 550px;
    background-color: white;
}


.Information-content-text {
    position: relative;
    width: 30%;
    height: 60%;
    top: 20%;
    margin-right: 20px;
    border-radius: 0 50px 50px 0;
    background-color: #0288d1;
    animation: slideInFromLeft 2s ease-out;
    

}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.Information-content-text h1 {
    color: white;
    font-size: 30px;
    padding: 10px;
    text-align: center;
}

.Information-content-text p {
    color: white;
    font-size: 20px;
    padding: 10px;
}

.InstagramFeed {
    margin-top: 50px;
    margin-right: 200px;
}

.google-reviews {
    margin-top: 50px;
    background-color: #f1f1f1;
}

@media only screen and (max-width: 1078px) {


    .Information-content-text {
        width: 40%;
    }

    .Information-content-text h1 {
        font-size: 25px;
    }

    .Information-content-text p {
        font-size: 18px;
    }

    .InstagramFeed {
        margin-left: 0;
        margin-right: 0;

    }
    
    

}

@media only screen and (max-width: 768px) {

    .Information-content {
        display: block;
        height: 100%;
    }

    .Information-content-text {
        width: 100%;
        height: 50%;
        top: 0;
        border-radius: 0;
    }

    .Information-content {
        display: flex;
        flex-direction: column;
    }

    .dots-container {
        top: 90%;
    }


    .Information-content-text h1 {
        font-size: 25px;
    }

    .Information-content-text p {
        font-size: 18px;
    }

    .InstagramFeed {
        margin-left: 125px;
        margin-top: 50px;
        margin-right: 0;
    }

    .google-reviews {
        margin-top: 50px;
    }

}

@media only screen and (max-width: 480px) {

    .dots-container {
        top: 90%;
    }


    .Information-content-text h1 {
        font-size: 20px;
    }

    .Information-content-text p {
        font-size: 16px;
    }

    .InstagramFeed {
        margin-left: 40;
        margin-right: 0;
    }

    .elfsight-app-3149d5bb-d68b-46be-b9a7-9789ac830a25 {
        max-width: 250px;
    }

    .google-reviews {
        margin-top: 50px;
    }
    .eapps-instagram-feed-posts-slider-next {
        display: none;
    }

}
