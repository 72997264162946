.back-pmenu-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
}

.back-pmenu-container .back-pmenu-button {
    width: 175px;
    background-color: #1591da;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 5px;
    cursor: pointer;
    transition: background-color 0.5s;
    font-size: 0.75rem;
    font-weight: bold;
}

.back-pmenu-container .back-pmenu-button:hover {
  background-color: #5fc3f5;
}