@import url('https://fonts.googleapis.com/css2?family=Amaranth:ital,wght@0,400;0,700;1,400;1,700&family=Inter:wght@700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.header{
    width: 100%;
    height: 10vh;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.7);
    margin-bottom: 2px;
    font-family: 'Amaranth', sans-serif;
}

.header-logo{
    display: flex;
    align-items: center;
}

.header-logo h1 {
    margin-left: 16px;
    font-size: 1.5vw;
    font-weight: bold;
}

.header-logo img {
    width: 25%;
    height: auto;
    margin-left: 16px;
    max-width: 100px;
}

.header-menu {
    display: flex;
    align-items: center;
    margin-right: 2%;
    margin-left: 2%;
}

.header-menu ul {
    display: flex;
    list-style-type: none;
    padding: 0;
}

.link {
    margin: 0 16px;
    border-radius: 5px;
    padding: 8px 16px;
    background-color: #6ab0d8;
    cursor: pointer;
}

.link:hover {
    background-color: #ADE1FF;
}

.header-menu li:last-child {
    margin-right: 16px;
}

.header-menu li:hover a {
    color: #000000;
}

.header-menu a {
    text-decoration: none;
    text-align: center;
    color: #ffffff;
    white-space: nowrap;
}

.menu-toggle {
    display: none;
    cursor: pointer;
    margin-right: 16px;
}

.menu-icon {
    width: 25px;
    height: 3px;
    background-color: #000;
    margin: 6px 0;
}


@media screen and (max-width: 900px) {
    .link {
        margin: 0 8px;
        padding: 4px 8px;
    }
}

@media screen and (max-width: 768px) {

    .header-logo h1 {
        font-size: 3vw;
    }

    .header-menu ul {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 10.5%;
        right: 0;
        background-color: #ffffff;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.7);
        z-index: 1000;

    }
    
    

    .header-menu ul li {
        margin: 10px 0;
    }

    .header-menu.open ul {
        display: flex;
    }

    .menu-toggle {
        display: block;
    }
}

@media screen and (max-width: 480px) {
    .header-logo h1 {
        font-size: 3vw;
    }
}
