.footer {
    position: static;
    bottom: 0;
    width: 100%;
}

.footer-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #f8f9fa;
}

.footer-social {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.footer-social h3 {
    color: #333;
    font-size: 18px;
    margin-bottom: 10px;
}

.footer-social ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-social li {
    margin-bottom: 5px;
    margin-left: 10px;
}

.footer-social li img {
    width: 45px;
    height: 45px;
    cursor: pointer;
}

.footer-copyright {
    margin-top: 20px;
    color: #000000;
    font-size: 14px;
}

.footer-copyright{
    margin-top: 25px;
}

.footer-copyright span {
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-link {
    color: #000000;
    text-decoration:underline;
}


@media screen and (max-width: 480px) {

    .footer-social {
        flex-direction: column;
    }

    .footer-social li {
        margin-bottom: 10px;
    }

    .footer-social li img {
        width: 50px;
        height: 50px;
    }
}
