.chatbot-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
}

.chatbot-container {
  position: fixed;
  bottom: 130px;
  right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  animation-name: slide-up;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  border: 1px solid #ccc;
  z-index: 999999;
}
.react-chatbot-kit-chat-inner-container {
  background-color: #f5f5f5;
}

.chatbot-container h1 {
  display: none;
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0); 
  }
}

.react-chatbot-kit-chat-container {
  width: 450px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.react-chatbot-kit-chat-message-container {
  overflow-x: hidden;
  background-color: #f5f5f5;

}

.react-chatbot-kit-chat-bot-message {
  border-radius: 10px;
  margin-top: 1px;
  margin-left: 10px;
  width: 80%;
  max-width: 100%;
}

.react-chatbot-kit-chat-btn-send {
  display: none;
}
.react-chatbot-kit-chat-input{
  display: none;
}


.chatbot-button-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-top: 10px;
  z-index: 9999999;
}

.chatbot-button-container .chatbot-button {
  width: 100px;
  height: 100px;
  background-color: #1591da;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.5s;
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chatbot-button-container img {
  width: 65px;
  height: 65px;
}


@media screen and (max-width: 480px) {

  .chatbot-main-container {
    width: 100%;
    height: 100%;
  }

  .chatbot-container {
    width: 60%;
    bottom: 120px;
  }

  .react-chatbot-kit-chat-container {
    width: 100%;
  }

  .chatbot-button-container {
    bottom: 20px;
    right: 20px;
  }



  .chatbot-button-container .chatbot-button {
    width: 70px;
    height: 70px;
    font-size: 1.2rem;
  }

  .chatbot-button-container img {
    width: 45px;
    height: 45px;
  }
}