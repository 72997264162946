.conditions-generales-container {
    max-width: 100%;
    margin: 20px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
}

.conditions-generales-container h1, 
.conditions-generales-container h2 {
    color: #0056b3;
}

.conditions-generales-container h1 {
    text-align: center;
    margin-bottom: 20px;
}

.conditions-generales-container h2 {
    margin-top: 20px;
}

.conditions-generales-container p {
    margin: 10px 0;
}

.conditions-generales-container ul {
    margin: 10px 0;
    padding-left: 20px;
}

.conditions-generales-container li {
    margin: 5px 0;
}
