.contact {
    padding: 20px;
    margin-bottom: 80px;
}

.contact-title {
    text-align: left;
    font-size: 24px;
    margin-left: 26rem;
    margin-bottom: 30px;
}

.form-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.form-group label {
    font-size: 18px;
    margin-bottom: 10px;
}

.form-group input {
    padding: 10px;
    font-size: 16px;
    background-color: #d9d9d9;
    border: none;
}

.form-group-email-number {
    display: flex;
    gap: 40px;
}

.form-group-email-number input {
    width: 400px;
}

.form-group-object input {
    width: 841px;
}

.form-group-message textarea {
    padding: 10px;
    font-size: 16px;
    background-color: #d9d9d9;
    border: none;
    width: 841px;
    height: 200px;
    resize: none;
}

.btn-contact {
    margin-top: 10px;
    padding: 10px 50px;
    font-size: 16px;
    background-color: #6ab0d8;
    color: #000;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.btn-contact:hover {
    background-color: #ADE1FF;
}

@media only screen and (max-width: 900px) {
    .contact-title {
        margin-left: 0;
    }

    .form-group-email-number input {
        width: 100%;
    }
    

    .form-group-object input,
    .form-group-message textarea {
        width: 480px;
    }

    .btn-contact {
        padding: 10px 50px;
    }
}


@media only screen and (max-width: 768px) {
    .contact-title {
        margin-left: 0;
    }

    .form-group-email-number input {
        width: 100%;
    }

    .form-group-object input,
    .form-group-message textarea {
        width: 480px;
    }

    .btn-contact {
        padding: 10px 50px;
    }
}

@media only screen and (max-width: 480px) {
    .contact-title {
        font-size: 20px;
    }

    .form-contact label {
        font-size: 3vw;
    }

    .form-group-email-number input {
        width: 100%;
    }

    .form-group-object {
        width: 100%;
    }

    .form-group-message {
        width: 100%;
    }

    .form-group-object input,
    .form-group-message textarea {
        width: 100%;
    }

    .btn-contact {
        padding: 10px 30px;
    }
}
