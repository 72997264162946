.contact-us-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-us-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    width: 80%;
    margin-top: 10px;
    background-color: #1591da;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
}

.contact-us-btn-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
}

.contact-us-btn-container .contact-us-button {
    width: 175px;
    background-color: #1591da;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 5px;
    cursor: pointer;
    transition: background-color 0.5s;
    font-size: 0.75rem;
    font-weight: bold;
}

.contact-us-btn-container .contact-us-button:hover {
  background-color: #5fc3f5;
}
